.button-swiper{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    z-index: 9;
    &.disabled{
        display: none;
    }
    &.scroll-up{
        top: 6rem;
    }
    &.scroll-down{
        bottom: 2.5rem;
    }
    .btn{
        font-size: 2rem !important;
        position: relative;
        z-index: 1;
    }
}
.home-swiper-arrows{
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    padding-top: 6rem;
    padding-bottom: 1rem;
}
.home-swiper{
    height: 100vh;
    margin-top: -5rem;
    .home-slide{
        height: 100%;
        transform: initial !important;
    }
}
.home-section{
    height: 100%;
    .body{
        position: relative;
        z-index: 9;
    }
    .scroll-down{
        position: relative;
        padding: 1rem;
        .btn{
            position: relative;
            z-index: 1;
            font-size: 2rem;
        }
    }
    &.first{
        padding-top: 5rem;
        background: var(--color-shadow);
        background: linear-gradient(0deg, var(--color-primary-container) 0%, var(--color-shadow) 100%);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        .background{
            pointer-events: none;
            position: absolute;
            inset: 0;
            .canva{
                opacity: .2;
            }
            
            @-moz-keyframes spin { 
                100% { -moz-transform: rotate(360deg); } 
            }
            @-webkit-keyframes spin { 
                100% { -webkit-transform: rotate(360deg); } 
            }
            @keyframes spin { 
                100% { 
                    -webkit-transform: rotate(360deg); 
                    transform:rotate(360deg); 
                } 
            }
            .deco{
                position: absolute;
                inset: 0;
                opacity: .1;
                .cube{
                    background: #fff;
                    position: absolute;
                    width: 50vmin;
                    rotate: 20deg;
                    border-radius: 2rem;
                    filter: blur(10px);
                    right: -5vmin;
                    aspect-ratio: 1 / 1;
                    bottom: calc(100% - 40vmin);
                    animation: spin 20s cubic-bezier(1,-0.03, 0, 0.96) infinite;
                }
            }
            .sun{
                --sun-width: 65vmin;
                width: var(--sun-width);
                left: calc(var(--sun-width) / 2 * -1);
                bottom: calc(var(--sun-width) / 2 * -1);
                aspect-ratio: 1 / 1;
                background: #FFF;
                border-radius: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                mix-blend-mode: difference;
                &::before,
                &::after{
                    content: "";
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    position: absolute;
                    border-radius: 100%;
                }
                &::before{
                    border: 1px solid #FFF;
                    width: calc(100% + 2.5rem);
                }
                &::after{
                    width: calc(100% + 5rem);
                    background-image: url('../../../assets/images/circle-dashed.png');
                    background-size: contain;
                    animation: spin 120s linear infinite;
                }
            }
        }
        h1{
            padding: 0 3rem;
            text-align: center;
            font-size: clamp(1.325rem, 3.25vmin, 3rem);
            display: flex;
            position: relative;
            flex-flow: column;
            gap: clamp(1.325rem, 1vmin, 3rem);
            .small{
                font-size: 1em;
            }
            .principal{
                font-size: 2em;
                position: relative;
            }
            .title-moon{
                position: absolute;
                left: calc(100% - 1em / 2.25);
                bottom: calc(100% - 1em / 1.5);
                width: 1.5em;
            }
        }
        .buttons{
            padding: 0 3rem;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            display: flex;
            gap: 1rem;
            justify-content: center;
        }
    }
    &.second{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
        background: var(--color-shadow);
        background: linear-gradient(180deg, var(--color-primary-container) 0%, var(--color-shadow) 100%);
        .body{
            display: flex;
            flex-flow: column;
            gap: 2rem;
        }
        h2{
            padding: 0 3rem;
            text-align: center;
            font-size: clamp(2rem, 4.25vmin, 3rem);
            display: flex;
            position: relative;
            flex-flow: column;
            gap: clamp(1.325rem, 1vmin, 3rem);
        }
        .content{
            gap: 2rem;
            max-width: 1200px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .service-card{
                border-radius: 1rem;
                display: flex;
                flex-flow: column;
                gap: .5rem;
                .service-layout{
                    display: flex;
                    flex-flow: column;
                    padding: .25rem;
                    border-radius: .75rem;
                    .service-image{
                        border: 3px solid #FFF;
                        border-radius: .75rem;
                        color: #000;
                        background: #000;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        font-size: 3.5rem;
                        padding: .25rem;
                        img{
                            border-radius: .5rem;
                            width: 100%;
                            aspect-ratio: 16 / 7;
                        }
                    }
                    
                    .btn{
                        padding: .75rem 1rem;
                        width: 70%;
                        margin: -1.75rem auto 0;
                        justify-content: flex-start;
                        border-radius: .75rem;
                        border: 2px solid #FFF;
                    }
                    .service-body{
                        color: #fff;
                        display: flex;
                        flex-flow: column;
                        gap: 1rem;
                        h3{
                            font-size: 1.3rem;
                            font-weight: 600;
                        }
                        .text{
                            display: flex;
                            flex-flow: column;
                            gap: .5rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 18em) {
    .home-section{
        &.second{
            .content{
                padding: 0 1rem;
                grid-template-columns: repeat(1, 1fr);
                .service-card{
                    max-width: 350px;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media (min-width: 40em) {
    .home-section{
        &.second{
            .content{
                grid-template-columns: repeat(2, 1fr);
                .service-card{
                    max-width: initial;
                }
            }
        }
    }
}
