.footer{
    height: 100%;
    background: var(--color-shadow);
    footer{
        padding: 2rem;
        .body{
            position: relative;
            padding-top: 3rem;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 10px; /* Altura del borde */
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='10' viewBox='0 0 120 10'%3E%3Cpath d='M0,5 C10,10 10,0 20,5 C30,10 30,0 40,5 C50,10 50,0 60,5 C70,10 70,0 80,5 C90,10 90,0 100,5 C110,10 110,0 120,5' stroke='%23fff' fill='none' stroke-width='2' stroke-dasharray='100,100' /%3E%3C/svg%3E");
                background-repeat: repeat-x;
            }
            .section{
                flex-flow: column;
                gap: 1rem;
                display: flex;
            }
            .logo{
                font-size: 2rem;
            }
            .socials{
                display: flex;
                flex-flow: row;
                gap: .5rem;
                font-size: 1.5rem;
                .btn{
                    font-size: 1em;
                }
            }
        }
    }
}