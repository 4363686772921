.products-page{
    display: flex;
    flex-flow: column;
    gap: 1rem;
    .products-banner{
        max-width: 900px;
        margin: 0 auto;
        padding: 4rem 1rem;
        font-size: 9vmin;
        text-align: center;
    }
    .products-list{
        background: #FFF;
        padding: 1rem;
        min-height: 60vh;
    }
}