.header{
    --more-info-height: 400px;
    height: 5rem;
    display: flex;
    align-items: center;
    padding: .75rem;
    position: sticky;
    top: 0;
    z-index: 99;
    gap: 0rem;
    border-bottom: 1px dashed var(--color-on-surface); /* Definir color, tamaño y estilo del borde */
    border-image: repeating-linear-gradient(90deg, var(--color-on-surface), var(--color-on-surface) 14px, transparent 14px, transparent 20px) 1;
    backdrop-filter: blur(4px);
    background-color: rgba($color: #000000, $alpha: .5);
    .yugashiki-resources{
        position: relative;
    }
    .logo{
        .btn{
            font-size: 1.75rem;
            padding: .25em;
        }
    }
    .navigation{
        padding: 0 .5rem;
        flex: 1;
        nav{
            a {
                color: #FFF;
                font-family: var(--quicksand);
                text-transform: uppercase;
            }
        }
    }
    .options{
        display: flex;
        align-items: center;
        gap: 0;
        .btn{
            height: 100%;
            font-size: 1.75rem;
        }
    }
}