:root {
    --quicksand: "Quicksand", sans-serif;
    --roboto: "Roboto", sans-serif;

    --color-primary: rgb(206 195 207);
    --color-surface-tint: rgb(206 195 207);
    --color-on-primary: rgb(52 46 55);
    --color-primary-container: rgb(12 8 15);
    --color-on-primary-container: rgb(161 151 163);
    --color-secondary: rgb(203 197 202);
    --color-on-secondary: rgb(51 47 51);
    --color-secondary-container: rgb(64 60 64);
    --color-on-secondary-container: rgb(213 207 212);
    --color-tertiary: rgb(255 255 255);
    --color-on-tertiary: rgb(47 49 49);
    --color-tertiary-container: rgb(212 212 212);
    --color-on-tertiary-container: rgb(62 64 64);
    --color-error: rgb(255 180 171);
    --color-on-error: rgb(105 0 5);
    --color-error-container: rgb(147 0 10);
    --color-on-error-container: rgb(255 218 214);
    --color-background: rgb(20 19 20);
    --color-on-background: rgb(230 225 226);
    --color-surface: rgb(20 19 20);
    --color-on-surface: rgb(230 225 226);
    --color-surface-variant: rgb(74 69 74);
    --color-on-surface-variant: rgb(204 196 202);
    --color-outline: rgb(149 143 149);
    --color-outline-variant: rgb(74 69 74);
    --color-shadow: rgb(0 0 0);
    --color-shadow-white: rgb(255,255,255);
    --color-scrim: rgb(0 0 0);
    --color-inverse-surface: rgb(230 225 226);
    --color-inverse-on-surface: rgb(49 48 49);
    --color-inverse-primary: rgb(99 92 102);
    --color-primary-fixed: rgb(234 223 235);
    --color-on-primary-fixed: rgb(31 26 34);
    --color-primary-fixed-dim: rgb(206 195 207);
    --color-on-primary-fixed-variant: rgb(75 68 78);
    --color-secondary-fixed: rgb(232 224 230);
    --color-on-secondary-fixed: rgb(29 27 30);
    --color-secondary-fixed-dim: rgb(203 197 202);
    --color-on-secondary-fixed-variant: rgb(73 69 74);
    --color-tertiary-fixed: rgb(226 226 226);
    --color-on-tertiary-fixed: rgb(26 28 28);
    --color-tertiary-fixed-dim: rgb(198 198 199);
    --color-on-tertiary-fixed-variant: rgb(69 71 71);
    --color-surface-dim: rgb(20 19 20);
    --color-surface-bright: rgb(58 57 57);
    --color-surface-container-lowest: rgb(15 14 15);
    --color-surface-container-low: rgb(28 27 28);
    --color-surface-container: rgb(32 31 32);
    --color-surface-container-high: rgb(43 42 42);
    --color-surface-container-highest: rgb(54 52 53);
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 1;
}

html{
    overflow-y: scroll;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
}

html,
body{
    max-width: 100vw;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--roboto);
}

body{
    width: 100%;
    color: var(--color-on-surface);
    -webkit-font-smoothing: antialiased;
    overflow: visible;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-shadow);
}

a,
.a{
    text-decoration: none;
}
svg,
svg.svg,
.svg{
    font-size: 1em;
    width: 1em;
    color: currentColor;
    > *{
        color: currentColor;
        fill: currentColor;
    }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    font-family: var(--quicksand);
    font-weight: 400;
    font-size: 1em;
}

.avatar-container{
    background: #FFF !important;
    color: #000 !important;
}

.font{
    &-quicksand{
        font-family: var(--quicksand);
        font-weight: 400;
    }
    &-roboto{
        font-family: var(--roboto);
    }
}

button.btn,
a.btn,
.btn{
    --padding-normal-btn: .75rem;
    padding: .25rem;
    font-size: 1rem;
    transition: .2s ease all;
    text-transform: none;
    gap: .5rem;
    overflow: hidden;
    color: currentColor;
    padding: var(--padding-normal-btn);
    span.title {
        display: flex;
        align-items: center;
        height: 1.5rem;
        padding: 0 .25rem;
        position: relative;
        z-index: 1;
    }
    span.title ~ svg{
        transition: .2s ease all;
        position: relative;
        z-index: 1;
    }
    .canva{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: .5;
    }
    &:hover{
        span.title ~ svg{
            transform: translateX(3px);
        }
    }
    &.disabled,
    &:disabled{
        opacity: .5;
    }
    &.font{
        &-quicksand{
            font-family: var(--quicksand);
            font-weight: 400;
        }
        &-roboto{
            font-family: var(--roboto);
        }
    }
    &.radius{
        &-full{
            border-radius: 100px;
        }
        &-partial{
            border-radius: .725rem;
        }
    }
    &.icon-btn{
        color: #FFF;
        aspect-ratio: 1 / 1;
        padding: .25em;
        min-width: 0;
        min-height: 0;
        width: auto;
        height: auto;
        font-size: 1rem;
        border-radius: 100px;
        svg{
            font-size: 1em;
            width: 1em;
            color: currentColor;
            > *{
                color: currentColor;
                fill: currentColor;
            }
        }
        &:hover{
            background: var(--color-surface-container-high);
        }
    }
    &.yugashiki-resources{
        color: #fff;
        padding: .25rem .5rem;
        span{
            font-size: 1.25rem;
        }
        &:hover{
            background: var(--color-surface-container-high);
        }
    }
    &.variant{
        &-white{
            background: #FFF;
            color: #000;
            &:hover{
                background: #DDD;
            }
        }
        &-black{
            color: #FFF;
            background: #000;
            &-000{
                background: #000;
            }
            &-111{
                background: #111;
            }
            &-222{
                background: #222;
            }
        }
        &-branch{
            &-1{
                padding: var(--padding-normal-btn);
                background: #bb8fce;
                color: #FFF;
                &:hover{
                    background: #d2b4de;
                }
            }
        }
    }
}

.container {
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }